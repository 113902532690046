<template>
  <BaseLayout>
    <SubHeader></SubHeader>
    <div class="container">
      <h4>My Filtered Searches</h4>
      <div class="d-flex justify-content-center align-items-center loading" v-if="loading"><a-spin /></div>
      <div class="d-flex justify-content-start flex-wrap listing-cards-container" v-if="loading === false" >
        <a-card hoverable class="listing-card" v-for="item in items" :key="item.id">
          <template class="ant-card-actions" #actions>
            <a-tooltip>
              <template #title>View</template>
              <EyeOutlined key="use" @click="useFilters(item)" />
            </a-tooltip>
            <a-pop-confirm title="Are you sure you want to delete this filter?" ok-text="Yes" cancel-text="No" @confirm="deleteFilter(item)">
              <a-tooltip>
                <template #title>Delete</template>
                <DeleteOutlined key="delete" />
              </a-tooltip>
            </a-pop-confirm>
          </template>
          <a-card-meta :title="item.name">
            <template #description></template>
          </a-card-meta>
        </a-card>
      </div>
      <div v-if="items.length === 0 && loading === false">
        No result found!
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import axios from 'axios';
import { EyeOutlined, DeleteOutlined } from '@ant-design/icons-vue';
import ASpin from 'ant-design-vue/lib/spin';
import ACard from 'ant-design-vue/lib/card';
import APopConfirm from 'ant-design-vue/lib/popconfirm';
import ATooltip from 'ant-design-vue/lib/tooltip';
import 'ant-design-vue/lib/card/style/index.css';
import 'ant-design-vue/lib/spin/style/index.css';
import 'ant-design-vue/lib/popover/style/index.css';
import 'ant-design-vue/lib/button/style/index.css';
import 'ant-design-vue/lib/tooltip/style/index.css';

import BaseLayout from '@/layouts/Base.vue';
import SubHeader from '@/components/SubHeader.vue';

export default {
  components: {
    BaseLayout,
    SubHeader,
    ASpin,
    ACard,
    'a-card-meta': ACard.Meta,
    EyeOutlined,
    DeleteOutlined,
    APopConfirm,
    ATooltip
  },
  data() {
    return {
      loading: false,
      items: []
    }
  },
  async mounted() {
    document.title = 'My Filtered Searches - Mane Street Market';
    window.scrollTo(0, 0);
    await this.loadItems();
  },
  methods: {
    async loadItems() {
      this.loading = true;
      try {
        let response = await axios.get('/api/my-filters');
        this.items = response.data.items;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    useFilters(item) {
      this.$router.push({path: '/search', query: item.filters});
    },
    async deleteFilter(item) {
      this.loading = true;
      try {
        await axios.delete(`/api/my-filters/${item.id}`);
        await this.loadItems();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .listing-cards-container {
    gap: 10px;
    margin-top: 24px;
  }

  .listing-card {
    width: calc(33% - 10px * 2 / 3);
    display: flex;
    flex-direction: column;

    ::v-deep(.ant-card-body) {
      height: 100%;

      .ant-card-meta-title {
        white-space: normal;
        overflow-wrap: anywhere;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .listing-card {
      width: calc(50% - 10px / 2);
    }
  }
</style>
